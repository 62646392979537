<template>
  <div class="pregame-page-container">

  <b-overlay
    :show="getRoomStatus === $getConst('ROOM_LOADING_GAME')"
    :variant="(isDarkTheme ? 'dark': 'light')"
    blur="0.4px"
    opacity="0.9"
    rounded="lg"
    z-index="3"
    >
    <template #overlay>
      <h4
      class="text-default text-center "
      :class="(isDarkTheme ? 'text-light': 'text-dark')">Loading game...</h4>
      <b-spinner class="align-middle"></b-spinner>
    </template>



    <Navbar></Navbar> 

    <GenericHeader :title="$t('view.pregame.roomHeader')"></GenericHeader>
    
    <PregamePlayerList
    ></PregamePlayerList>


      <GenericHeader :title="$t('view.pregame.fileHeader')"></GenericHeader>

            <b-overlay
              :show="getMyUsername != getRoomHost"
              :variant="(isDarkTheme ? 'dark': 'light')"
              blur="0.3px"
              opacity="0.9"
              rounded="lg"
              z-index="3"
              >
              <PregameFileTree
              class="pt-1"
              ></PregameFileTree>

              <template #overlay>
                <h4
                class="text-default text-center "
                :class="(isDarkTheme ? 'text-light': 'text-dark')">Host is choosing game options...</h4>
              </template>
            </b-overlay>

    <GenericHeader :title="$t('view.pregame.optionHeader')"></GenericHeader>

    <b-overlay
      :show="getMyUsername != getRoomHost"
      :variant="(isDarkTheme ? 'dark': 'light')"
      blur="0.3px"
      opacity="0.9"
      rounded="lg"
      z-index="3"
      class=""
      >
      <!-- add padding on bottom because of fixed footer (to have spacing inbetween) -->
      <PregameGameOptions
        class="pt-2"
        ref="pregameGameOptions"
      ></PregameGameOptions>

      <template #overlay>
        <h4
        class="text-default text-center "
        :class="(isDarkTheme ? 'text-light': 'text-dark')">Host is choosing game options...</h4>
      </template>
    </b-overlay>

    <PregameStartButton 
      style="padding-top: 5rem;"
      :alert="alert"
      @startGame="startGame"
      :isHost="getMyUsername === getRoomHost"
      :arePlayersReady="checkPlayersStatus"
      :isFileSelected="isFileSelected">
    </PregameStartButton>

  </b-overlay>

  </div>
</template>

<script>

import GenericHeader from '@/components/GenericHeader.vue'
import PregamePlayerList from '@/components/pregame/PregamePlayerList.vue'
import PregameFileTree from '@/components/pregame/PregameFileTree.vue'
import PregameGameOptions from '@/components/pregame/PregameGameOptions.vue'
import PregameStartButton from '@/components/pregame/PregameStartButton.vue'
import Navbar from '@/components/Navbar.vue'

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  title() {
    return this.$t('view.pregame.name')
  },
  components: {
    GenericHeader,
    PregamePlayerList,
    PregameFileTree,
    PregameGameOptions,
    PregameStartButton,
    Navbar,
  },

  methods: {
    startGame: function() {
      this.updateSelectedFileIds();

      var gameOptionsSelected = this.$refs.pregameGameOptions.game_options;

      // convert time from mm:ss to seconds for startGameWs
      var game_time_seconds = parseInt(gameOptionsSelected.timer.ss);
      game_time_seconds = game_time_seconds + 60*parseInt(gameOptionsSelected.timer.mm);

      var payload = {
        tour_duration: game_time_seconds,
        file_ids_selected: this.selectedFileIds,
        max_points: gameOptionsSelected.points,
        mode: gameOptionsSelected.mode,
      };

      console.debug('starting gameWs...');
      this.startGameWs(payload)
    },

    
    ...mapActions([
      'resetPoints',
      'clearSelectedItems',

      'updateSelectedCards',
      'updateSelectedFileIds',

      'startGameWs',
      'getUserData',
      'addGameCards',
      'getUsers',
      'connectPlayerGameWs',
      'closePlayerGameWs',
    ]),
  },

  computed: {
    alert() {
      // special alert message if not the host
      if (this.getMyUsername != this.getRoomHost) {
        return this.$t('view.pregame.notHostAlert')
      }
      // assure that at least one file is selected before starting the game
      else if (this.isFileSelected === false) {
        return this.$t('view.pregame.fileSelectAlert');
      }
      // assure that all player are ready before allowing the host to start the game
      else if (this.checkPlayersStatus === false) {
        return this.$t('view.pregame.playersReadyAlert');
      }
      // the game can start once all players are ready and at least one file is selected
      else {
        return '';
      }
    },
    ...mapGetters([
      'checkPlayersStatus',
      'isFileSelected',
      'getMyUsername',
      'getRoomHost',
      'getRoomStatus',
    ]),
    ...mapState({
      playerGameSocket: state => state.game.playerGameSocket,
      isDarkTheme: state => state.settings.isDarkTheme,
      selectedFileIds: state => state.pregame.selectedFileIds,
    })
  },
  beforeRouteEnter (to, from, next) {
    next( vm => {
      vm.clearSelectedItems();
      vm.getUsers();
      vm.getUserData().then(() => {

        // if we navigated here from Play.vue view we are already connected to room,
        // otherwise connect to playerGameWs
        if (vm.playerGameSocket == undefined) {
          vm.connectPlayerGameWs(vm.$route.params.roomid)
          .then(() => {
          console.debug('successively connected to playerGameWs from URL page')
          })
          .catch(() => {
          console.error('unable to connect to playerGameWs')
          })
        }
      });
    });
  },
  beforeRouteLeave (to, from, next) {
    if (to.path != '/game' && this.playerGameSocket != undefined) {
      this.closePlayerGameWs();
    }
    next();
  }
}

</script>