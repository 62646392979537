<template>
  <div>
    <b-container class="pregame-footer">
      
      
      <b-row
        style="height:100%;"
        align-v="center"
        v-if="isHost == false" 
        align-h="center">
        <b-col>
          <h3 class = "pregame-fileselect-alert my-2">{{ alert }}</h3>
        </b-col>
      </b-row>


      <b-row
        style="height:100%;"
        align-v="center"
        v-else-if="(isFileSelected == false || arePlayersReady == false)" 
        align-h="center">
        <b-col>
          <h3 class = "pregame-fileselect-alert my-2">{{ alert }}</h3>
        </b-col>
      </b-row>

      <b-row 
        v-else
        class="my-3" 
        align-h="center">
        <b-button        
          class="pregame-start-button mx-3"
          @click="$emit('startGame')">
            {{$t('view.pregame.startGameButton')}}
        </b-button>
      </b-row>
  </b-container>
  </div>
</template>

<script>

export default {
  props: {
    
    isHost: {
      type: Boolean,
      default: false,
    },

    isFileSelected: {
      type: Boolean,
      default: false,
    },
    arePlayersReady: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: String,
      default: '',
    }
  }
}


</script>