<template>
  <div>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" sm="8" md="6" lg="5">
          <div class="my-tree rounded mb-2">

            <!-- Use disable branch nodes prop on Treeselect components ?? 
            The branch nodes (folders) will behave as "collapsable folders" and only
            the files (leaf nodes) can be multi-selected -->

            <!-- If a "user" collapsable folder is opened, then we have to fetch the data
            for that branch node (API call). This API call is performed via publicdata -->

            <!-- USER DATA -->
            <Treeselect
              :value="selectedUserItems"
              @input="updateSelectedUserItems"
              disableBranchNodes
              :placeholder="$t('view.pregame.searchbarPlaceholder') + '...'"
              :autoLoadRootOptions="false"
              value-format="object"
              value-consists-of="LEAF_PRIORITY"
              :flatten-search-results="true"
              flat
              :autoSelectDescendants="true"
              :autoDeselectDescendants="true"
              :options="UserData" 
              :normalizer="normalizerUserTree"
              :limit="2"
              always-open
              :max-height="300"
              :searchable="true"
              :multiple="true"
              z-index="0"
              >
            </Treeselect>

            <!-- PUBLIC DATA -->
            <Treeselect
              :value="selectedPublicItems"
              @input="updateSelectedPublicItems"
              :loadOptions="getPublicUserFolders"
              disableBranchNodes
              :placeholder="$t('view.pregame.searchbarPlaceholder') + '...'"
              :autoLoadRootOptions="false"
              value-format="object"
              value-consists-of="LEAF_PRIORITY"
              :flatten-search-results="true"
              flat
              :autoSelectDescendants="false"
              :autoDeselectDescendants="false"
              :options="PublicData" 
              :normalizer="normalizerPublicTree"
              :limit="2"
              always-open
              :max-height="300"
              :searchable="true"
              :multiple="true"
              z-index="0"
              >
            </Treeselect>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { mapState, mapActions } from 'vuex'

  export default {
    components: {
      Treeselect,
    },
    methods: {
    ...mapActions([
      'updateSelectedUserItems',
      'updateSelectedPublicItems',
      'getPublicUserFolders',
    ]),

      normalizerUserTree(node) {
        //console.debug('normalizerUserTree call',node)
        if (node == undefined || node == null) {
          return node;
        }
        else {
          var normalized_node = {};

          // this boolean is explicitly set after fetching user's data in store
          if (node.isRoot === true) {
            normalized_node.id = this.$getConst('ROOT') + node.id;
            normalized_node.label = '👤 ' + this.$t('view.pregame.myFiles');
            normalized_node.children = [...node.files, ...node.folders];
          }

          // only folders and root has defined files/folders properties, but
          // we already checked for the root node above, thus the isRoot boolean check
          // is not necessary (included for explicitly)
          else if (node.files != undefined && node.folders != undefined && node.isRoot == undefined) {
            normalized_node.id = this.$getConst('FOLDER') + node.id;
            normalized_node.label = '📁 ' + node.name;
            normalized_node.children = [...node.files, ...node.folders];
            node.isFolder = true;
          }

          // only files are a defined card property!
          else if (node.cards != undefined) {
            normalized_node.id = this.$getConst('FILE') + node.id;
            normalized_node.label = '📄 ' + node.name;
            node.isFile = true;
          }
          else {
            console.error('Normalizer error: didnt find root, folder, or file')
          }
          return normalized_node;
        }
      },
      normalizerPublicTree(node) {
        //console.debug('normalizerPublicTree call',node)
        if (node == undefined || node == null) {
          return node;
        }
        else {
          var normalized_node = {};

          // the public root boolean is explicitly set after fetching list of users in store
          if (node.isPublicRoot === true) {
            normalized_node.id = this.$getConst('PUBLIC_ROOT');
            normalized_node.label = '🌍 ' + this.$t('view.pregame.publicFiles');
            normalized_node.children = node.users;
          }

          // only user nodes have a username property, so we can check that
          else if (node.username != undefined) {
            normalized_node.id = this.$getConst('USER') + node.username;
            normalized_node.label = '👤 ' + node.username;

            // on first pass, the node.children property should be set to null
            // by the getUsers action (see publicdata.js module)
          }

          // only folders and root has defined files/folders properties, but
          // we already checked for the root node above, thus the isPublicRoot 
          // is not necessary (included for explicitly)
          else if (node.files != undefined && node.folders != undefined && node.isPublicRoot == undefined) {
            normalized_node.id = this.$getConst('FOLDER') + node.id;
            normalized_node.label = '📁 ' + node.name;
            normalized_node.children = [...node.files, ...node.folders];
            node.isFolder = true;
          }

          // only files are a defined card property!
          else if (node.cards != undefined) {
            normalized_node.id = this.$getConst('FILE') + node.id;
            normalized_node.label = '📄 ' + node.name;
            node.isFile = true;
          }
          else {
            console.error('Normalizer error: didnt find root, folder, or file')
          }
          return normalized_node;
        }
      },

    },
    computed: {
      ...mapState ({
          UserData: state => state.userdata.UserData,
          PublicData: state => state.publicdata.PublicData,
          selectedUserItems: state => state.pregame.selectedUserItems,
          selectedPublicItems: state => state.pregame.selectedPublicItems,
        }),
    },
  }
</script>
