<template>
    <b-container class= "justify-content-start">
      <b-row align-h="center">
       <b-col cols="10" sm="7" md="6" lg="5">

         <!-- RED TEAM -->
        <b-table-lite
          class="pregame-section-container"
          empty-text="No red players joined"
          no-border-collapse
          fixed
          small
          show-empty
          sticky-header = "350px"
          :dark="(isDarkTheme ? true: false)"
          :busy="isBusy"
          :items="getPlayersInRoom"
          :fields="fields">

          <!------------ TABLE BUSY --------------->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading players...</strong>
            </div>
          </template>

          <!------------ PLAYER TEAM --------------->
          <template #cell(team) = "data">
            <b-button
              v-if="getMyUsername === data.item.username"
              @click="clickChangeTeam(data.index, data.value)"
              pill 
              :disabled="changePlayerTeamDisable"
              :variant="(data.value === $getConst('RED')) ? 'danger' : 'primary'">
              {{ (data.value === $getConst('RED')) ? 'Red' : 'Blue'}}
            </b-button>
            <b-button
              v-else
              disabled
              pill 
              :variant="(data.value === $getConst('RED')) ? 'danger' : 'primary'">
              {{ (data.value === $getConst('RED')) ? 'Red' : 'Blue'}}
            </b-button>
          </template>

          <!------------ PLAYER STATUS --------------->
          <template #cell(status)="data">
            <b-button 
              v-if="getMyUsername === data.item.username"
              :disabled = "changePlayerStatusDisable"
              @click="clickChangeStatus(data.index)"
              style="font-size: 0.9em;" 
              :variant="data.value === 1 ? 'success' : 'secondary'">
                {{ data.value === 1 ? 'READY':'NOT READY'}}
            </b-button>
            <b-button 
              v-else
              disabled
              style="font-size: 0.9em;" 
              :variant="data.value === 1 ? 'success' : 'secondary'">
                {{ data.value === 1 ? 'READY':'NOT READY'}}
            </b-button>


          </template>

          <!------------ PLAYER NAME --------------->
          <template #cell(username) = "data">
            <i class="fa fa-user-o icon-state-default"></i> {{data.value}}
          </template>

          <!------------ TABLE BUSY --------------->
          <template #empty="scope">
            <h4>{{ scope.emptyText }}</h4>
          </template>

        </b-table-lite>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      isBusy: false,
      changePlayerTeamDisable: false,
      changePlayerStatusDisable: false,
      fields: [
        {
          key: 'username',
          label: 'Name',
        },
        { 
          key: 'team', 
        },
        {
          key: 'status',
        },
      ]
    }
  },
  methods: {
    
    clickChangeTeam() {
      // stop user from rechanging team until after change is done in backend
      this.changePlayerTeamDisable = true;
      this.changePlayerTeam()
      .then(() => {
        this.changePlayerTeamDisable = false;
      })
      .catch(() => {
        console.error('Error changing team')
      })
    },

    clickChangeStatus() {
      // stop user from rechanging status until after change is done in backend
      this.changePlayerStatusDisable = true;
      this.changePlayerStatus()
      .then(() => {
        this.changePlayerStatusDisable = false;
      })
      .catch(() => {
        console.error('Error changing status');
      })

    },

    ...mapActions([
      'changePlayerTeam',
      'changePlayerStatus',
    ])
  },
  computed: {
    ...mapState({
      isDarkTheme: state => state.settings.isDarkTheme
    }),
    ...mapGetters([
      'getPlayersInRoom',
      'getMyUsername',
    ])
  },
}
</script>