<template>
    <b-container class= "justify-content-start">
    <b-row align-h="center">
      <b-col cols="10" sm="7" md="6" lg="5">
        <div class="pregame-section-container rounded mb-3">

         <b-row align-v="center">
           <b-col offset="1">
            <b-form-group :label="$t('view.pregame.mode')" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="game_options.mode"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component">
                <b-form-radio :value="$getConst('TNT')">{{$t('view.pregame.tnt')}}</b-form-radio>
                <b-form-radio disabled :value="$getConst('ARCADE')">{{$t('view.pregame.arcade')}}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
           </b-col>
         </b-row>
          
          <b-row align-h="center" align-v="center" class="mb-2">
            <b-col cols="4" sm="4">
              <label for="timer">{{$t('view.pregame.timer')}}</label>
            </b-col>
            <b-col cols="6" sm="6">
              <vue-timepicker
                @error="handleTimePickerError"
                :minute-range="[[0, 60]]"
                :second-range="[[15, 60]]"
                hide-disabled-items
                input-width="100%"
                manual-input
                drop-direction="up"
                @input="timePicked"
                v-model="game_options.timer"
                format="mm:ss"
                id="timer">
                <template v-slot:icon>
                  <b-icon 
                  icon="clock" 
                  :variant="isDarkTheme ? 'light' : 'dark'" 
                  aria-hidden="true"></b-icon>
                </template>
                <template v-slot:clearButton>
                  <b-icon 
                  icon="x" 
                  :variant="isDarkTheme ? 'light' : 'dark'" 
                  aria-hidden="true"></b-icon>
                </template>
              </vue-timepicker>
            </b-col>
          </b-row>

          <b-row align-h="center" align-v="center" class="my-1">
            <b-col cols="4" sm="4">
              <label for="points">
                <span v-if="game_options.mode === $getConst('ARCADE')">
                  {{$t('view.pregame.winningPoints')}}
                </span>
                <span v-else-if="game_options.mode === $getConst('TNT')">
                  {{$t('view.pregame.lifePoints')}}
                </span>
              </label>
            </b-col>
            <b-col cols="6" sm="6">
              <b-form-input 
                v-model="game_options.points" 
                id="points" 
                type="number" 
                max="100"
                min="1"></b-form-input>
            </b-col>
          </b-row>

        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { mapState } from 'vuex'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  components: {
    VueTimepicker,
  },
  data() {
    return {
      game_options: {
        mode: this.$getConst('TNT'),
        points: 8,
        timer: {
          mm: '01', // minutes
          ss: '30'  // seconds
        },
      }
    }
  },
  methods: {
    handleTimePickerError(event){
      console.log('Time picked error event: ',event)
      /* manually set seconds to lowest range so user can't manually input
      a lower value*/
      this.timer.ss = '15';
    },
    timePicked(event) {
      console.debug('Time picked event: ',event);
    }
  },
  computed: {
    ...mapState({
      isDarkTheme: state => state.settings.isDarkTheme
    }),
  }
}
</script>
